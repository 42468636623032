// i18next-extract-mark-ns-start products-pisdyn-pubs

import { PISDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductPisdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Available PISDYN Publications</h2>
						<ul>
							<li>
								<p>
									Piston Design for Optimizing Trade-off of
									Friction and NVH (
									<a
										href="http://papers.sae.org/2016-01-1855/"
										target="_blank"
										rel="noreferrer">
										SAE 2016-01-1855
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Tribological Behavior of Low Viscosity
									Lubricants in the Piston to Bore Zone of a
									Modern Spark Ignition Engine (
									<a
										href="http://papers.sae.org/2014-01-2859/"
										target="_blank"
										rel="noreferrer">
										SAE 2014-01-2859
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Effect of Design and Operational Parameters of
									Piston Skirt on the Performance and Efficiency
									of a Small Gasoline Engine (
									<a
										href="http://www.sae.org/technical/papers/2014-01-2016"
										target="_blank"
										rel="noreferrer">
										SAE 2014-01-2016
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									The Influence of Piston Secondary Motion in the
									Liner Cavitation Occurrence&nbsp;(
									<a
										href="http://www.sae.org/technical/papers/2008-01-1193"
										target="_blank"
										rel="noreferrer">
										SAE 2008-01-1193
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									How to Predict Piston Slap-Noise Using 3D Piston
									Motion Simulation (
									<a
										href="http://www.sae.org/technical/papers/2007-01-1245"
										target="_blank"
										rel="noreferrer">
										SAE 2007-01-1245
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Piston Assembly Friction Losses
									-&nbsp;Comparison of Measured and Predicted Data
									(
									<a
										href="http://www.sae.org/technical/papers/2006-01-0426"
										target="_blank"
										rel="noreferrer">
										SAE&nbsp;2006-01-0426
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									A Comprehensive Piston Skirt Lubrication Model
									Using a Mass Conserving EHL Algorithm&nbsp;(
									<a
										href="http://www.sae.org/technical/papers/2005-01-1640"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-1640
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Modelling of Piston-Cylinder Lubrication with a
									Flexible Skirt and Cylinder Wall (
									<a
										href="http://www.sae.org/technical/papers/2000-01-2804"
										target="_blank"
										rel="noreferrer">
										SAE 2000-01-2804
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									An Integrated Design Analysis Methodology to
									Address Piston Tribological Issues&nbsp;(
									<a
										href="http://www.sae.org/technical/papers/930793"
										target="_blank"
										rel="noreferrer">
										SAE Paper No. 930793, 1993
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									Simulation of Secondary Dynamics of Articulated
									and Conventional Piston Assemblies&nbsp;(
									<a
										href="http://www.sae.org/technical/papers/920484"
										target="_blank"
										rel="noreferrer">
										SAE Paper No. 920484, 1992
									</a>
									)
								</p>
							</li>
							<li>
								<p>
									A Comprehensive Model of Piston Skirt
									Lubrication&nbsp;(
									<a
										href="http://www.sae.org/technical/papers/920483"
										target="_blank"
										rel="noreferrer">
										SAE Paper No. 920483, 1992
									</a>
									)
								</p>
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{PISDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductPisdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-pisdyn-pubs", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/pisdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

